<template>
  <nav class="pagination">
    <slot name="prev" v-bind="{ isDisabled: !canGoPrev, go, prev: getPrev }">
      <div v-if="hasArrows" class="pagination__item prev">
        <router-link
          :to="hasRouter ? getLinkTo(getPrev) : ''"
          :disabled="!hasRouter && !canGoPrev ? true : false"
          aria-label="Go to previous page"
          @click="hasRouter ? null : go(getPrev)"
          class="py10 flex middle-xs h4"
        >
          <i class="icon-left cl-black fs-medium" :class="{'cl-white' : !canGoPrev && variant === 2 }" />
          <span class="pl5 h6" v-show="canGoPrev && variant === 1">Poprzednia</span>
        </router-link>
      </div>
    </slot>
    <template v-if="variant === 2">
      <input
        id="paginationInput"
        type="number"
        :min="1"
        :max="total"
        :value="currentPage"
        class="m0 no-outline base-input-number__input brdr-cl-primary bg-cl-transparent h4"
        @blur="goToLink($event.target.value)"
      >
    </template>
    <template v-if="showFirst && variant !== 2">
      <slot name="number" v-bind="{ page: 1 }">
        <router-link
          class="pagination__item"
          :class="{
            'button--pure': !hasRouter,
          }"
          :to="hasRouter ? getLinkTo(1) : ''"
          @click="hasRouter ? null : go(1)"
        >
          1
        </router-link>
      </slot>
      <slot v-if="firstVisiblePageNumber > 2" name="points">
        <div class="pagination__item">
          ...
        </div>
      </slot>
    </template>
    <template v-if="variant !== 2" v-for="page in limitedPageNumbers">
      <slot name="number" v-bind="{ page, currentPage }">
        <router-link
          :key="page"
          class="pagination__item"
          :class="{
            'button--pure': !hasRouter && currentPage !== page,
            current: currentPage === page,
          }"
          :to="hasRouter && currentPage !== page ? getLinkTo(page) : ''"
          @click="!hasRouter && currentPage !== page ? go(page) : null"
        >
          {{ page }}
        </router-link>
      </slot>
    </template>
    <template v-if="showLast || variant === 2">
      <slot v-if="lastVisiblePageNumber < total - 1 || variant === 2" name="points">
        <div class="pagination__item">
          z
        </div>
      </slot>
      <slot name="number" v-bind="{ page: total }">
        <router-link
          class="pagination__item"
          :class="{
            'button--pure': !hasRouter,
          }"
          :to="hasRouter ? getLinkTo(total) : ''"
          @click="hasRouter ? null : go(total)"
        >
          {{ total }}
        </router-link>
      </slot>
    </template>
    <slot name="next" v-bind="{ isDisabled: !canGoNext, go, next: getNext }">
      <div v-if="hasArrows" class="pagination__item next">
        <router-link
          :to="hasRouter ? getLinkTo(getNext) : ''"
          :disabled="!hasRouter && !canGoNext ? true : false"
          aria-label="Go to previous page"
          @click="hasRouter ? null : go(getNext)"
          class="py10 flex middle-xs h4"
        >
          <span v-show="canGoNext && variant === 1" class="pl5 h6">Następna</span>
          <i class="icon-right cl-black fs-medium" :class="{'cl-white' : !canGoNext && variant === 2 }" />
        </router-link>
      </div>
    </slot>
  </nav>
</template>
<script>
export default {
  name: 'ListingPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    visible: {
      type: Number,
      default: 5
    },
    hasArrows: {
      type: Boolean,
      default: true
    },
    current: {
      type: Number,
      default: 1
    },
    pageParamName: {
      type: String,
      default: 'page'
    },
    variant: {
      type: Number,
      default: 1
    }
  },
  computed: {
    hasRouter () {
      return this.$route;
    },
    currentPage () {
      return this.hasRouter
        ? this.$route.query[this.pageParamName]
          ? parseInt(this.$route.query[this.pageParamName], 10)
          : 1
        : this.current;
    },
    getPrev () {
      return this.currentPage === this.firstVisiblePageNumber
        ? this.currentPage
        : this.currentPage - 1;
    },
    canGoPrev () {
      return this.currentPage !== this.firstVisiblePageNumber;
    },
    getNext () {
      return this.currentPage === this.lastVisiblePageNumber
        ? this.currentPage
        : this.currentPage + 1;
    },
    canGoNext () {
      return this.currentPage !== this.lastVisiblePageNumber;
    },
    showFirst () {
      return this.firstVisiblePageNumber > 1;
    },
    showLast () {
      return this.lastVisiblePageNumber < this.total;
    },
    listOfPageNumbers () {
      return Array.from(Array(this.total), (_, i) => i + 1);
    },
    limitedPageNumbers () {
      if (this.total <= this.visible) {
        return this.listOfPageNumbers;
      }
      if (this.currentPage < this.visible - Math.floor(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(0, this.visible);
      }
      if (
        this.total - this.currentPage <
        this.visible - Math.ceil(this.visible / 2) + 1
      ) {
        return this.listOfPageNumbers.slice(this.total - this.visible);
      }
      return this.listOfPageNumbers.slice(
        this.currentPage - Math.ceil(this.visible / 2),
        this.currentPage + Math.floor(this.visible / 2)
      );
    },
    firstVisiblePageNumber () {
      return this.limitedPageNumbers[0];
    },
    lastVisiblePageNumber () {
      return this.limitedPageNumbers[this.limitedPageNumbers.length - 1];
    }
  },
  methods: {
    go (page) {
      this.$emit('click', page);
    },
    goToLink (page) {
      this.$router.push({ path: this.$route.path, query: { page: page } })
    },
    getLinkTo (page) {
      const pageNumber = page.toString();
      if (this.hasRouter) {
        return {
          ...this.$route,
          query: { ...this.$route.query, [this.pageParamName]: page }
        };
      } else {
        return pageNumber;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  &__item {
    margin-left: 8px;
    margin-right: 8px;
    color: #343434;
  }
  &__item.current {
    color: #ff8100;
  }
}
#paginationInput {
  border: 1px solid #D9D9D9;
    border-radius: 19px;
    padding: 9px;
    font-size: 14px;
    text-align: center;
    width: 16px;
    line-height: 11px;
    height: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0 3px;
}
#paginationInput::-webkit-outer-spin-button,
#paginationInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
