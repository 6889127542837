<template>
  <div class="col-md-2 start-xs category-filters relative pr0">
    <div class="sidebar" id="sidebar-desktop" :class="[{ 'fixed': !bottom && filtersVisible },{ 'bottom-fixed' : bottom && filtersVisible }]" :style="heightFromTop">
      <div class="mb15">
        <p
          class="m0 pointer uppercase cl-primary-orange"
          @click="resetAllFilters"
          v-show="hasActiveFilters || isQueryNotEmpty"
        >
          {{ $t('Wyczyść filtry') }}
        </p>
        <active-filters :filters="getCurrentFilters" :label-width="labelWidth" :label-height="labelHeight" @change="$emit('changeFilter', $event)" />
      </div>
      <div class="filters">
        <p class="py5 cl-black brdr-bottom-1 brdr-cl-alternative mb10 mt0 uppercase h5 flex pointer">
          {{ $t('Kategoria') }}
        </p>
        <div class="mb10" v-if="isHideBackToLogic && getCustomParentCategory">
          Cofnij do:
          <router-link
            class="cl-orange pointer"
            :to="localizedRoute({ path: '/' + getCustomParentCategory.url_path, query: $route.query})"
          >
            {{ getCustomParentCategory.name }}
          </router-link>
        </div>
        <div class="relative pb10" :class="{'visible-box': isHideCategory}">
          <div
            v-for="singleCategory in children"
            v-if="singleCategory.product_count !== 0"
            :key="singleCategory.slug"
            :class="{'ml10': isHideBackToLogic}"
            class="mb10 scroll-box flex middle-xs py3"
          >
            <router-link
              class="no-underline category-filter mr10 cl-black"
              :class="{ active: getCurrentCategory.id === singleCategory.id }"
              :to="localizedRoute({path: '/' + singleCategory.url_path, query: $route.query})"
            >
              {{ singleCategory.name }}
            </router-link>
          </div>
          <div v-if="children.length && children.length > 5" @click="isHideCategory = !isHideCategory" class="show-button fs-medium-small cl-dark-blue absolute flex middle-xs pointer">
            <template v-if="!isHideCategory">
              Zwiń
            </template>
            <template v-else>
              Więcej
            </template>
            <i :class="isHideCategory ? 'down' : 'up'" />
          </div>
        </div>
        <p class="pt15 pb5 cl-black brdr-bottom-1 brdr-cl-alternative mb10 mt0 uppercase h5 flex pointer">
          {{ $t('Status') }}
        </p>
        <div class="filter-label w-100">
          <span
            class="filter-label uppercase"
            @click="toggleNewFilter()"
            :class="{ active: isNewFilterActive }"
          >
            {{ $t('Nowości') }}
          </span>
        </div>
        <div class="filter-label w-100">
          <span
            class="filter-label uppercase"
            @click="toggleSaleFilter()"
            :class="{ active: isSaleFilterActive }"
          >
            {{ $t('Wyprzedaż') }}
          </span>
        </div>
        <div
          v-for="(filter, filterIndex) in availableFilters"
          :key="filterIndex"
          v-if="filterIndex === 'bestseller' || filterIndex === 'hit_of_the_month' || filterIndex === 'hit_of_the_week'"
        >
          <special-selector
            context="category"
            class="block"
            :code="filterIndex"
            v-for="(option, index) in filter"
            v-if="option.label === 'Yes'"
            :key="index"
            :variant="option"
            :selected-filters="getCurrentFilters"
            @change="$emit('changeFilter', $event)"
          />
        </div>
        <div
          v-for="(filter, filterIndex) in availableFilters"
          :key="filterIndex"
          v-if="filterIndex !== 'bestseller' && filterIndex !== 'hit_of_the_month' && filterIndex !== 'hit_of_the_week' && filterIndex !== 'color_aggregated'"
        >
          <p class="relative flex start-xs pt15 pb5 cl-black brdr-bottom-1 brdr-cl-alternative mb10 mt0 uppercase h5 flex middle-xs">
            {{ $t(filterIndex + '_filter') }}
            <span v-if="filterIndex === 'configurable_children.power_75_65_20_int'"
                  class="ml10 flex middle-xs pointer"
                  @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('power75')"
            >
              <i class="icon-info cl-alternative h4" />
            </span>
            <span v-if="filterIndex === 'width'" class="discount-tooltip_container inline relative">
              <i class="icon-info cl-alternative h4" />
              <span class="discount-tooltip width" />
            </span>
            <span v-if="filterIndex === 'height'" class="discount-tooltip_container inline relative">
              <i class="icon-info cl-alternative h4" />
              <span class="discount-tooltip height" />
            </span>
          </p>
          <div v-if="filterIndex === 'configurable_children.color_aggregated'" class="relative pt5 flex wrap">
            <color-selector
              context="category"
              code="color"
              v-for="(color, index) in filter"
              :key="index"
              :variant="color"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
          <div v-else-if="filterIndex === 'width'" class="flex wrap scroll-box">
            <attribute-slider
              context="category"
              code="width"
              :id="'width'"
              :from="10"
              :to="300"
              content="Width "
              label="Width Label"
              @change="selectedDimension($event.arrId, $event.type, $event.label)"
            />
          </div>
          <div v-else-if="filterIndex === 'height'" class="flex wrap scroll-box">
            <attribute-slider
              context="category"
              code="height"
              :id="'height'"
              :from="10"
              :to="300"
              content="Height "
              label="Height Label"
              @change="selectedDimension($event.arrId, $event.type, $event.label)"
            />
          </div>
          <div v-else class="flex wrap scroll-box">
            <generic-selector
              context="category"
              class="block"
              :code="filterIndex"
              v-for="(option, index) in filter"
              :key="index"
              :variant="option"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
        </div>
        <product-info-modal :text="selectedText" />
      </div>
    </div>
  </div>
</template>

<script>
import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'
import { mapGetters } from 'vuex'
import ProductInfoModal from 'theme/components/core/blocks/Product/ProductInfoModal.vue'
import AttributeSlider from 'theme/components/core/AttributeSlider'
import ColorSelector from 'theme/components/core/ColorSelector'
import GenericSelector from 'theme/components/core/GenericSelector'
import SpecialSelector from 'theme/components/core/SpecialSelector'
import pickBy from 'lodash-es/pickBy'
import config from 'config'
import ActiveFilters from './ActiveFilters'

export default {
  components: {
    ColorSelector,
    SpecialSelector,
    ProductInfoModal,
    GenericSelector,
    ActiveFilters,
    AttributeSlider
  },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      filtersVisible: false,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      scrollHeight: 190,
      bottom: false,
      heightFromTop: '',
      isHideCategory: true,
      isHideColor: true,
      labelWidth: '',
      labelHeight: '',
      selectedText: ''
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getCategories: 'category/getCategories',
      getCustomParentCategory: 'category-next/getCustomParentCategory'
    }),
    isHideBackToLogic () {
      return this.getCurrentCategory.id !== 54 && this.getCurrentCategory.id !== 9
    },
    hasActiveFilters () {
      return this.$store.getters['category-next/hasActiveFilters']
    },
    isQueryNotEmpty () {
      return Object.keys(this.$route.query).length
    },
    getCurrentFilters () {
      return this.$store.getters['category-next/getCurrentFilters']
    },
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    },
    diliveryFilter () {
      return this.$store.state.ui.diliveryFilter
    },
    widthFilter () {
      return this.$store.state.ui.widthFilter
    },
    heightFilter () {
      return this.$store.state.ui.heightFilter
    },
    children () {
      if (!config.entities.category.categoriesDynamicPrefetch && (this.getCurrentCategory.children_data && this.getCurrentCategory.children_data.length > 0 && this.getCurrentCategory.children_data[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.getCurrentCategory.children_data
      } else {
        return this.getCategories.filter(c => { return c.parent_id === this.getCurrentCategory.id }) // return my child categories
      }
    },
    availableFilters () {
      return pickBy(this.filters, (filter, filterType) => { return (filter.length && !this.$store.getters['category-next/getSystemFilterNames'].includes(filterType)) })
    },
    activeFilters () {
      return this.getActiveCategoryFilters
    }
  },
  beforeMount () {
    this.$bus.$on('has-scrolled', this.onHasScrolled)
  },
  beforeDestroy () {
    this.$bus.$off('has-scrolled', this.onHasScrolled)
  },
  methods: {
    showTooltip (option) {
      console.log(option)
    },
    onHasScrolled () {
      this.hasScrolledSidebar()
      this.bottom = this.bottomVisibleSidebar()
    },
    changeModalText (label) {
      if (label) {
        this.selectedText = label
      }
    },
    resetAllFilters () {
      this.$store.dispatch('category-next/resetSearchFilters')
      this.$store.dispatch('ui/resetSaleFilter')
      this.$store.dispatch('ui/resetNewFilter')
      this.$store.dispatch('ui/resetDeliveryFilter')
    },
    toggleSaleFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleSaleFilter').then(() => {
        if (!this.isSaleFilterActive) {
          delete query.sale
          this.$router.push({ query })
        } else {
          query.sale = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleNewFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleNewFilter').then(() => {
        if (!this.isNewFilterActive) {
          delete query.new
          this.$router.push({ query })
        } else {
          query.new = 'true'
          this.$router.push({ query })
        }
      })
    },
    selectedDimension  (arrId, type, label) {
      if (type === 'width') {
        let query = Object.assign({}, this.$route.query)
        if (this.widthFilter !== label) {
          this.$store.dispatch('ui/toggleWidthFilter', { arrId, label }).then(() => {
            if (this.widthFilter === '') {
              delete query.width
              this.$router.push({ query })
            } else {
              query.width = label
              this.$router.push({ query })
            }
          })
        } else {
          delete query.width
          this.$router.push({ query })
          this.$store.dispatch('ui/resetWidthFilter')
        }
        this.labelWidth = 'SZEROKOŚĆ ' + label
      } else {
        let query = Object.assign({}, this.$route.query)
        if (this.heightFilter !== label) {
          this.$store.dispatch('ui/toggleHeightFilter', { arrId, label }).then(() => {
            if (this.heightFilter === '') {
              delete query.height
              this.$router.push({ query })
            } else {
              query.height = label
              this.$router.push({ query })
            }
          })
        } else {
          delete query.height
          this.$router.push({ query })
          this.$store.dispatch('ui/resetHeightFilter')
        }
        this.labelHeight = 'WYSOKOŚĆ ' + label
      }
    },
    toggleDeliveryFilter (value) {
      let query = Object.assign({}, this.$route.query)
      if (this.diliveryFilter !== value) {
        this.$store.dispatch('ui/toggleDeliveryFilter', value).then(() => {
          if (this.diliveryFilter === '') {
            delete query.delivery
            this.$router.push({ query })
          } else {
            query.delivery = value
            this.$router.push({ query })
          }
        })
      } else {
        delete query.delivery
        this.$router.push({ query })
        this.$store.dispatch('ui/resetDeliveryFilter')
      }
    },
    sortById (filters) {
      return [...filters].sort((a, b) => {
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
    },
    bottomVisibleSidebar () {
      const element = document.getElementById('sidebar-desktop') ? document.getElementById('sidebar-desktop').scrollHeight : 0
      const categoryDesc = document.getElementById('category-desc') ? document.getElementById('category-desc').scrollHeight : 0
      const SAFETY_MARGIN = document.getElementById('main-footer') ? document.getElementById('main-footer').scrollHeight : 0
      const scrollY = window.scrollY
      const visible = window.innerHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = scrollY + element + 113 >= pageHeight - SAFETY_MARGIN - categoryDesc
      // const bottomOfPage = visible + scrollY - 410 + element >= pageHeight
      let heightFromTop1 = pageHeight - element - categoryDesc - SAFETY_MARGIN - 300
      this.heightFromTop = `top: ${heightFromTop1}px;`
      return bottomOfPage || pageHeight < visible
    },
    hasScrolledSidebar () {
      this.scrollTop = window.scrollY
      const sidebar = document.getElementById('sidebar-desktop') ? document.getElementById('sidebar-desktop').scrollHeight : 0
      const listing = document.getElementById('product-listing') ? document.getElementById('product-listing').scrollHeight : 0
      if ((this.scrollTop > this.scrollHeight) && (sidebar < listing)) {
        this.filtersVisible = true
      } else {
        this.filtersVisible = false
      }
      this.lastScrollTop = this.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-secondary: color(secondary);
$color-white: color(white);
$color-dim-gray: color(dim-gray);
$color-persian-red: color(persian-red);
$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(alternative, $colors-border);

.category-filter {
  &:hover {
    text-decoration: underline;
  }
}
.discount-tooltip_container {
  padding: 2px 5px;
  .discount-tooltip {
    display: none;
    position: absolute;
    height: auto;
    z-index: 10;
    min-height: 300px;
    min-width: 250px;
    max-width: 300px;
    bottom: 25px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }
  .width {
    background: url("../../../../assets/svg/width.svg") no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #fff;
  }
  .height {
    background: url("../../../../assets/svg/height.svg") no-repeat;
    background-size: cover;
    background-color: #fff;
  }
  &:hover .discount-tooltip {
    display: block;
  }
}

.visible-box {
  max-height: 155px;
  overflow: hidden;
}
.show-button {
  bottom: 0;
  right: 2px;
  i {
    margin-left: 8px;
    border: solid #041D59;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.price-box {
  max-width: 90%;
  margin: 0 auto;
}
.scroll-box {
  overflow-x: hidden;
  max-height: 180px;
  overflow-y: auto;
}
.sidebar {
  &.fixed {
    padding-right: 10px;
    max-height: calc( 100vh - 150px );
    overflow-y: auto;
    position: fixed;
    width: 212px;
    top: 119px !important;
    @media (max-width: 1200px) {
      width: 144px;
    }
    @media (max-width: 992px) {
      width: 106px;
    }
  }
  &.bottom-fixed {
    position: absolute;
    width: 204px;
    top: 1000px;
    @media (max-width: 1200px) {
      width: 144px;
    }
    @media (max-width: 992px) {
      width: 106px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__inline-selecors {
    display: flex;
  }
}
.category-filters {
  width: 242px;
}
.nav-link {
  @media (max-width: 991px) {
    font-size: 14px;
  }
  &::before {
    position: absolute;
    content: '';
    width: 14px;
    left: -30px;
    top: 1px;
    border: 1px solid #041D59;
    height: 14px;
  }
}
.nav-link:hover, .nav-link.active {
  &::after{
    position: absolute;
    content: '';
    background-color: #041D59;
    width: 8px;
    left: -27px;
    top: 4px;
    border: 1px solid #041D59;
    height: 8px;
  }
}
.special-link {
  &::before {
    position: absolute;
    content: '';
    width: 14px;
    left: -30px;
    top: 6px;
    border: 1px solid #041D59;
    height: 14px;
  }
}
.special-link:hover, .special-link.active {
  &::after{
    position: absolute;
    content: '';
    background-color: #041D59;
    width: 8px;
    left: -27px;
    top: 9px;
    border: 1px solid #041D59;
    height: 8px;
  }
}
.close-icon {
  font-size: 8px;
}
.filter-label {
  line-height: 30px;
  font-size: 16px;
  opacity: 1;
  transition: opacity .2s;
  @media (max-width: 991px) {
    font-size: 14px;
  }
  &.no-products-left {
    opacity: .3;
    cursor: not-allowed;
    span.filter-label {
      cursor: not-allowed;
    }
  }
  span.filter-label {
    cursor: pointer;
    position: relative;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      margin-left: 20px;
    }
    &:before {
      content: '';
      position: relative;
      top: 3px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 14px;
      height: 14px;
      border: 1px solid $border-secondary;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (min-width: 767px) {
      &:hover {
        &:before {
          background: $bg-secondary;
        }
        &:after {
          content: '';
          position: absolute;
          left: 3px;
          top: 6px;
          background: #041D59;
          width: 10px;
          height: 10px;
        }
      }
    }
    &.active {
      &::before {
        background: white;
      }
      &:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 6px;
        background: #041D59;
        width: 10px;
        height: 10px;
      }
    }
  }
  span.product-counter {
    float: right;
    opacity: .2;
  }
}
</style>
