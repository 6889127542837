<template>
  <div class="w-100 h-auto">
    <div class="hidden-xs price-slider-container">
      <no-ssr placeholder="loading..." placeholader-tag="span">
        <vue-slider
          v-model="value"
          :clickable="false"
          :height="2"
          :min="from"
          :max="to"
          :tooltip-formatter="'{value} cm'"
          :tooltip-placement="['bottom', 'bottom']"
          :tooltip="'always'"
          :dot-style="{
            'backgroundColor': '#041D59',
            'color': '#fff',
            'border-color': '#041D59',
            'height': '14px',
            'width': '14px',
            'border-radius': '15px'
          }"
          :tooltip-style="{
            'backgroundColor': '#041D59',
            'color': '#fff',
            'border-color': '#041D59',
            'padding': '1px 4px',
          }"
          :process-style="{
            'backgroundColor': '#041D59'
          }"
          :rail-style="{
            'height': '4px',
            'backgroundColor': '#D9D9D9'
          }"
          @drag-end="getLabelfor"
        />
      </no-ssr>
    </div>
    <div class="visible-xs">
      <div v-if="code === 'width'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(0, 29.9)"
          :class="{ active: value[0] === 0 && value[1] === 29.9 && isWidthInQuery }"
        >
          0 - 29
        </span>
      </div>
      <div v-if="code === 'height'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(0, 39.9)"
          :class="{ active: value[0] === 0 && value[1] === 39.9 && isHeightInQuery }"
        >
          0 - 39
        </span>
      </div>
      <div v-if="code === 'width'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(30, 59.9)"
          :class="{ active: value[0] === 30 && value[1] === 59.9 && isWidthInQuery }"
        >
          30 - 59
        </span>
      </div>
      <div v-if="code === 'height'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(40, 69.9)"
          :class="{ active: value[0] === 40 && value[1] === 69.9 && isHeightInQuery }"
        >
          40 - 69
        </span>
      </div>
      <div v-if="code === 'width'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(60, 99.9)"
          :class="{ active: value[0] === 60 && value[1] === 99.9 && isWidthInQuery }"
        >
          60 - 99
        </span>
      </div>
      <div v-if="code === 'height'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(70, 99.9)"
          :class="{ active: value[0] === 70 && value[1] === 99.9 && isHeightInQuery }"
        >
          70 - 99
        </span>
      </div>
      <div v-if="code === 'width'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(100, 149.9)"
          :class="{ active: value[0] === 100 && value[1] === 149.9 && isWidthInQuery }"
        >
          100 - 149
        </span>
      </div>
      <div v-if="code === 'height'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(100, 149.9)"
          :class="{ active: value[0] === 100 && value[1] === 149.9 && isHeightInQuery }"
        >
          100 - 149
        </span>
      </div>
      <div v-if="code === 'width'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(150, 300)"
          :class="{ active: value[0] === 150 && value[1] === 300 && isWidthInQuery }"
        >
          150 >
        </span>
      </div>
      <div v-if="code === 'height'">
        <span
          class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
          @click="getLabelforCheckbox(150, 300)"
          :class="{ active: value[0] === 150 && value[1] === 300 && isHeightInQuery }"
        >
          150 >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import filterMixin from 'theme/mixins/filterMixin.ts'

const AttributeSlider = {}

if (process.browser) {
  let VueSlider = require('vue-slider-component')
  AttributeSlider['vue-slider'] = VueSlider
}
AttributeSlider['no-ssr'] = NoSSR

export default {
  name: 'AttributeSlider',
  props: {
    content: {
      type: null,
      default: ''
    },
    id: {
      type: null,
      required: true
    },
    code: {
      type: null,
      required: true
    },
    from: {
      type: null,
      required: true
    },
    to: {
      type: null,
      required: true
    },
    context: {
      type: null,
      default: ''
    }
  },
  data () {
    return {
      active: false,
      value: [Math.floor(this.from), Math.ceil(this.to)]
    }
  },
  beforeMount () {
    this.$bus.$on('filter-reset', this.filterReset)
    this.$bus.$on('filter-changed-' + this.context, this.filterChanged)
  },
  beforeDestroy () {
    this.$bus.$off('filter-reset', this.filterReset)
    this.$bus.$off('filter-changed-' + this.context, this.filterChanged)
  },
  watch: {
    $route: {
      handler (to) {
        if (to && this.code && !to.query[this.code]) {
          this.value = [Math.floor(this.from), Math.ceil(this.to)]
        }
      }
    }
  },
  computed: {
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    isWidthInQuery () {
      if (this.$route.query['width']) {
        return true
      } else {
        return false
      }
    },
    isHeightInQuery () {
      if (this.$route.query['height']) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    getLabelforCheckbox (from, to) {
      this.value = [from, to]
      if (this.listByCode && this.listByCode[this.code]) {
        let range = this.listByCode[this.code].options.filter(el => {
          return el.label >= this.value[0] * 10 && el.label <= this.value[1] * 10
        })
        let arrId = range.map(el => {
          return parseInt(el.value)
        })
        this.setAttribute(arrId)
      }
    },
    getLabelfor () {
      if (this.listByCode && this.listByCode[this.code]) {
        let range = this.listByCode[this.code].options.filter(el => {
          return el.label >= this.value[0] * 10 && el.label <= this.value[1] * 10
        })
        let arrId = range.map(el => {
          return parseInt(el.value)
        })
        this.setAttribute(arrId)
      }
    },
    filterReset (filterOption) {
      this.active = false
    },
    filterChanged (filterOption) {
      if (filterOption.attribute_code === this.code) {
        if (filterOption.id === this.id) {
          if (this.active) {
            this.active = false
          } else {
            this.active = true
          }
        } else {
          this.active = false
        }
        // filterOption.id === this.id ? this.active = true : this.active = false
      }
    },
    setAttribute (arrId) {
      // let obj = {
      //   from: labelFrom.value,
      //   to: labelTo.value
      // }
      this.$emit('change', {
        'arrId': arrId,
        'type': this.code,
        'label': this.value[0] + '-' + this.value[1]
      })
    }
  },
  components: AttributeSlider,
  mixins: [filterMixin]
}
</script>

<style lang="scss">
.price-slider-container {
  .vue-slider-dot {
    z-index: 1;
    .vue-slider-dot-handle {
      // box-shadow: none;
      // background-color: #000;
      // width: 14px;
      // height: 14px;
      // border-radius: 50%;
    }
  }
  .vue-slider-dot-tooltip-bottom {
    width: max-content;
    margin: 2px 3px;
    font-size: 13px;
    left: 25% !important;
    &::after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
    }
  }
}
</style>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-event: color(tertiary);
  $color-active: color(accent);

  .nav-link {
    &::before {
      position: absolute;
      content: '';
      width: 14px;
      left: -30px;
      top: 1px;
      border: 1px solid #191919;
      height: 14px;
    }
  }
  .nav-link.active {
    &::after{
      position: absolute;
      content: '';
      background-color: #191919;
      width: 8px;
      left: -27px;
      top: 4px;
      border: 1px solid #191919;
      height: 8px;
    }
  }
  .price-slider-container {
    max-width: 75%;
    width: 75%;
    margin: 0 auto;
    padding-bottom: 50px;
    touch-action: none;
  }

  .price-selector {
    width: 20px;
    height: 20px;
    touch-action: none;

    &.active {
      .square {
        background-color: $color-active;
      }
    }
  }

  .square {
    touch-action: none;
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
</style>
