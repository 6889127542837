<template>
  <div class="filter-label w-100">
    <span
      class="filter-label capitalize"
      @click="$emit('change', variant)"
      :aria-label="variant.label"
      :class="{'active': isActive}"
    >
      <template v-if="variant.label === 'WM'">
        <span class="transform-none">Wodny z opcją elektryczną</span>
      </template>
      <template v-else>
        {{ beautify(variant.label) }}
      </template>
    </span>
  </div>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  mixins: [filterMixin],
  methods: {
    beautify (label) {
      if (label === 'W') {
        return 'Wodny'
      } else if (label === 'E') {
        return 'Elektryczny'
      } else if (label === 'M') {
        return 'Wodno-elektryczny'
      } else if (label === 'Yes') {
        return 'Tak'
      } else if (label === 'No') {
        return 'Nie'
      } else {
        return label
      }
    }
  },
  computed: {
    listByCode () {
      return this.$store.state.attribute.list_by_code
    }
  }
}
</script>

<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-secondary: color(secondary);
$color-white: color(white);
$color-dim-gray: color(dim-gray);
$color-persian-red: color(persian-red);
$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(alternative, $colors-border);

.transform-none {
  white-space: normal !important;
  text-transform: none;
}
.filter-label {
  line-height: 30px;
  font-size: 16px;
  opacity: 1;
  transition: opacity .2s;
  @media (max-width: 991px) {
    font-size: 14px;
  }
  &.no-products-left {
    opacity: .3;
    cursor: not-allowed;
    span.filter-label {
      cursor: not-allowed;
    }
  }
  span.filter-label {
    cursor: pointer;
    position: relative;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      margin-left: 20px;
    }
    &:before {
      content: '';
      position: relative;
      top: 3px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 14px;
      height: 14px;
      border: 1px solid $border-secondary;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (min-width: 767px) {
      &:hover {
        &:before {
          background: $bg-secondary;
        }
        &:after {
          content: '';
          position: absolute;
          left: 3px;
          top: 6px;
          background: #041D59;
          width: 10px;
          height: 10px;
        }
      }
    }
    &.active {
      &::before {
        background: white;
      }
      &:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 6px;
        background: #041D59;
        width: 10px;
        height: 10px;
      }
    }
  }
  span.product-counter {
    float: right;
    opacity: .2;
  }
}
</style>
