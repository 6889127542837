var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"pagination"},[_vm._t("prev",[(_vm.hasArrows)?_c('div',{staticClass:"pagination__item prev"},[_c('router-link',{staticClass:"py10 flex middle-xs h4",attrs:{"to":_vm.hasRouter ? _vm.getLinkTo(_vm.getPrev) : '',"disabled":!_vm.hasRouter && !_vm.canGoPrev ? true : false,"aria-label":"Go to previous page"},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.getPrev)}}},[_c('i',{staticClass:"icon-left cl-black fs-medium",class:{'cl-white' : !_vm.canGoPrev && _vm.variant === 2 }}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.canGoPrev && _vm.variant === 1),expression:"canGoPrev && variant === 1"}],staticClass:"pl5 h6"},[_vm._v("Poprzednia")])])],1):_vm._e()],null,{ isDisabled: !_vm.canGoPrev, go: _vm.go, prev: _vm.getPrev }),_vm._v(" "),(_vm.variant === 2)?[_c('input',{staticClass:"m0 no-outline base-input-number__input brdr-cl-primary bg-cl-transparent h4",attrs:{"id":"paginationInput","type":"number","min":1,"max":_vm.total},domProps:{"value":_vm.currentPage},on:{"blur":function($event){return _vm.goToLink($event.target.value)}}})]:_vm._e(),_vm._v(" "),(_vm.showFirst && _vm.variant !== 2)?[_vm._t("number",[_c('router-link',{staticClass:"pagination__item",class:{
          'button--pure': !_vm.hasRouter,
        },attrs:{"to":_vm.hasRouter ? _vm.getLinkTo(1) : ''},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(1)}}},[_vm._v("\n        1\n      ")])],null,{ page: 1 }),_vm._v(" "),(_vm.firstVisiblePageNumber > 2)?_vm._t("points",[_c('div',{staticClass:"pagination__item"},[_vm._v("\n        ...\n      ")])]):_vm._e()]:_vm._e(),_vm._v(" "),_vm._l((_vm.limitedPageNumbers),function(page){return (_vm.variant !== 2)?[_vm._t("number",[_c('router-link',{key:page,staticClass:"pagination__item",class:{
          'button--pure': !_vm.hasRouter && _vm.currentPage !== page,
          current: _vm.currentPage === page,
        },attrs:{"to":_vm.hasRouter && _vm.currentPage !== page ? _vm.getLinkTo(page) : ''},on:{"click":function($event){!_vm.hasRouter && _vm.currentPage !== page ? _vm.go(page) : null}}},[_vm._v("\n        "+_vm._s(page)+"\n      ")])],null,{ page: page, currentPage: _vm.currentPage })]:_vm._e()}),_vm._v(" "),(_vm.showLast || _vm.variant === 2)?[(_vm.lastVisiblePageNumber < _vm.total - 1 || _vm.variant === 2)?_vm._t("points",[_c('div',{staticClass:"pagination__item"},[_vm._v("\n        z\n      ")])]):_vm._e(),_vm._v(" "),_vm._t("number",[_c('router-link',{staticClass:"pagination__item",class:{
          'button--pure': !_vm.hasRouter,
        },attrs:{"to":_vm.hasRouter ? _vm.getLinkTo(_vm.total) : ''},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.total)}}},[_vm._v("\n        "+_vm._s(_vm.total)+"\n      ")])],null,{ page: _vm.total })]:_vm._e(),_vm._v(" "),_vm._t("next",[(_vm.hasArrows)?_c('div',{staticClass:"pagination__item next"},[_c('router-link',{staticClass:"py10 flex middle-xs h4",attrs:{"to":_vm.hasRouter ? _vm.getLinkTo(_vm.getNext) : '',"disabled":!_vm.hasRouter && !_vm.canGoNext ? true : false,"aria-label":"Go to previous page"},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.getNext)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.canGoNext && _vm.variant === 1),expression:"canGoNext && variant === 1"}],staticClass:"pl5 h6"},[_vm._v("Następna")]),_vm._v(" "),_c('i',{staticClass:"icon-right cl-black fs-medium",class:{'cl-white' : !_vm.canGoNext && _vm.variant === 2 }})])],1):_vm._e()],null,{ isDisabled: !_vm.canGoNext, go: _vm.go, next: _vm.getNext })],2)}
var staticRenderFns = []

export { render, staticRenderFns }