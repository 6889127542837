<template>
  <div class="col-md-2 p0 start-xs category-filters relative">
    <div class="sidebar" id="sidebar-mobile">
      <div class="filters safari-save">
        <p class="m0 py10 px20">
          Sortowanie i kategoria
        </p>
        <p class="p20 brdr-bottom-1 brdr-cl-grey-smoke m0 capitalize fs-15 flex between-xs middle-xs relative"
           @click="toggleCurrent('sort')"
        >
          <span class="absolute h6 cl-matterhorn category-label">Sortowanie</span>
          {{ $t(sortby.label) }}
          <i class="arrow" :class="toggleFilters.has('sort') ? 'top': 'bottom'" />
        </p>
        <div class="relative">
          <transition-group name="slide-fade">
            <div
              v-show="toggleFilters.has('sort')"
              v-for="variant in sortingVariants"
              :key="variant.id"
            >
              <span
                class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
                @click="$emit('changeFilter', variant), changeOrder(variant)"
                :class="{ active: sortby.label === variant.label && isSortInQuery}"
              >
                {{ $t(variant.label) }}
              </span>
            </div>
          </transition-group>
        </div>
        <p class="p20 m0 capitalize fs-15 flex between-xs middle-xs relative"
           @click="toggleCurrent('category')"
        >
          <span class="absolute h6 cl-matterhorn category-label">Kategoria</span>
          {{ slugToText(getCurrentCategory.slug) }}
          <i class="arrow" :class="toggleFilters.has('category') ? 'top': 'bottom'" />
        </p>
        <div class="relative">
          <loader-svg v-if="isShowLoader" class="absolute loader-position" />
          <transition-group name="slide-fade">
            <div key="transition">
              <div
                v-show="toggleFilters.has('category')"
                v-if="children.length === 0"
                class="h4 py15"
                @click="isShowLoader = true"
              >
                <router-link
                  class="ml20 fs-15 cl-black"
                  :to="localizedRoute('/' + getParentCategory[0].url_path)"
                >
                  Cofnij do <span class="cl-primary-orange cofnij">{{ getParentCategory[0].name }}</span>
                </router-link>
              </div>
              <div
                v-show="toggleFilters.has('category')"
                :key="singleCategory.slug"
                v-for="singleCategory in children"
                v-if="singleCategory.product_count !== 0"
                class="h4 py15"
                @click="isShowLoader = true"
              >
                <router-link
                  class="category-filter no-underline ml20 fs-15 cl-black"
                  :class="{ active: getCurrentCategory.id === singleCategory.id }"
                  :to="localizedRoute('/' + singleCategory.url_path)"
                >
                  {{ singleCategory.name }}
                </router-link>
              </div>
            </div>
          </transition-group>
        </div>
        <!-- Second part filters --> <!--  v-show="hasActiveFilters || isQueryNotEmpty" -->
        <div class="divider bg-cl-white-smoke" />
        <div class="row m0 pt20 pb10 px20 between-xs">
          <p class="m0 ">
            Filtry
          </p>
          <p class="m0 cl-primary-orange uppercase" @click="resetAllFilters">
            Wyczyść
          </p>
        </div>
        <active-filters :filters="getCurrentFilters" :label-width="labelWidth" :label-height="labelHeight" @change="$emit('changeFilter', $event)" />
        <p class="p20 brdr-bottom-1 brdr-cl-grey-smoke m0 h5 flex between-xs middle-xs" @click="toggleCurrent('status')">
          {{ $t('Status') }}
          <i class="arrow" :class="toggleFilters.has('status') ? 'top': 'bottom'" />
        </p>
        <transition-group name="slide-fade">
          <div key="transition-new" v-show="toggleFilters.has('status')">
            <span
              class="nav-link relative no-underline ml50 mr10 lh30 uppercase cl-black"
              @click="toggleNewFilter()"
              :class="{ active: isNewFilterActive }"
            >
              {{ $t('Nowości') }}
            </span>
          </div>
          <div key="transition-sale" v-show="toggleFilters.has('status')">
            <span
              class="nav-link relative no-underline ml50 mr10 lh30 uppercase cl-black"
              @click="toggleSaleFilter()"
              :class="{ active: isSaleFilterActive }"
            >
              {{ $t('Wyprzedaż') }}
            </span>
          </div>
          <div
            v-show="toggleFilters.has('status')"
            v-for="(filter, filterIndex) in availableFilters"
            :key="filterIndex + 'status'"
            v-if="filterIndex === 'bestseller' || filterIndex === 'hit_of_the_month' || filterIndex === 'hit_of_the_week' || filterIndex === 'outlet'"
          >
            <special-selector
              context="category"
              class="block"
              :code="filterIndex"
              v-for="(option, index) in filter"
              v-if="option.label === 'Yes'"
              :key="index + 'special'"
              :variant="option"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
        </transition-group>
        <div
          v-for="(filter, filterIndex) in availableFilters"
          :key="filterIndex"
          v-if="filterIndex !== 'bestseller'
            && filterIndex !== 'hit_of_the_month'
            && filterIndex !== 'hit_of_the_week'
            && filterIndex !== 'outlet'
            && filterIndex !== 'product_right_away'
            && filterIndex !== 'color_aggregated'
          "
        >
          <p class="p20 brdr-bottom-1 brdr-cl-grey-smoke m0 h5 flex between-xs middle-xs" @click="toggleCurrent(filterIndex)">
            {{ $t(filterIndex + '_filter') }}
            <i class="arrow" :class="toggleFilters.has(filterIndex) ? 'top': 'bottom'" />
          </p>
          <div v-if="filterIndex === 'configurable_children.color_aggregated'" :class="{'pt10 flex wrap': toggleFilters.has(filterIndex)}">
            <color-selector
              v-show="toggleFilters.has(filterIndex)"
              context="category"
              code="color"
              v-for="(color, index) in filter"
              :key="index"
              :variant="color"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
          <div v-else-if="filterIndex === 'width'" class="flex wrap scroll-box" @click.native="toggleCurrent(filterIndex)">
            <attribute-slider
              v-show="toggleFilters.has(filterIndex)"
              context="category"
              code="width"
              :id="'width'"
              :from="10"
              :to="300"
              content="Width "
              label="Width Label"
              @change="selectedDimension($event.arrId, $event.type, $event.label)"
            />
          </div>
          <div v-else-if="filterIndex === 'height'" class="flex wrap scroll-box" @click.native="toggleCurrent(filterIndex)">
            <attribute-slider
              v-show="toggleFilters.has(filterIndex)"
              context="category"
              code="height"
              :id="'height'"
              :from="10"
              :to="300"
              content="Height "
              label="Height Label"
              @change="selectedDimension($event.arrId, $event.type, $event.label)"
            />
          </div>
          <div v-else class="flex wrap">
            <transition-group name="slide-fade">
              <generic-selector
                v-show="toggleFilters.has(filterIndex)"
                context="category"
                class="block"
                :code="filterIndex"
                v-for="(option, index) in filter"
                :key="index + 'generic'"
                :variant="option"
                :selected-filters="getCurrentFilters"
                @change="$emit('changeFilter', $event)"
              />
            </transition-group>
          </div>
        </div>
        <!-- <div class="safari-save">
          <p class="p20 brdr-bottom-1 brdr-cl-grey-smoke m0 h5 flex between-xs middle-xs" @click="toggleCurrent('delivery')">
            Dostępność
            <i class="arrow" :class="toggleFilters.has('delivery') ? 'top': 'bottom'" />
          </p>
          <transition-group name="slide-fade">
            <div key="transition-delivery" v-show="toggleFilters.has('delivery')">
              <div>
                <span
                  class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
                  @click="toggleDeliveryFilter('24h')"
                  :class="{ active: diliveryFilter === '24h' }"
                >
                  Wysyłka 24h
                </span>
              </div>
              <div>
                <span
                  class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
                  @click="toggleDeliveryFilter('7-14')"
                  :class="{ active: diliveryFilter === '7-14' }"
                >
                  Wysyłka 7-14 dni
                </span>
              </div>
              <div>
                <span
                  class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
                  @click="toggleDeliveryFilter('15-30')"
                  :class="{ active: diliveryFilter === '15-30' }"
                >
                  Wysyłka 15-30 dni
                </span>
              </div>
              <div>
                <span
                  class="nav-link relative no-underline ml50 mr10 lh35 cl-black"
                  @click="toggleDeliveryFilter('31-60')"
                  :class="{ active: diliveryFilter === '31-60' }"
                >
                  Wysyłka 31-60 dni
                </span>
              </div>
            </div>
          </transition-group>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'
import { mapGetters } from 'vuex'
import ColorSelector from 'theme/components/core/ColorSelector'
import GenericSelector from 'theme/components/core/GenericSelector'
import pickBy from 'lodash-es/pickBy'
import config from 'config'
import ActiveFilters from './ActiveFilters'
import LoaderSvg from 'theme/components/theme/blocks/Icons/LoaderSvg.vue'
import SpecialSelector from 'theme/components/core/SpecialSelector'
import SortBy from '@vue-storefront/core/compatibility/components/SortBy'
import { products } from 'config'
import AttributeSlider from 'theme/components/core/AttributeSlider'

export default {
  components: {
    ColorSelector,
    SpecialSelector,
    GenericSelector,
    ActiveFilters,
    LoaderSvg,
    AttributeSlider
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  mixins: [SortBy],
  data () {
    return {
      isShowLoader: false,
      globalMapIncludeCategory: '',
      toggleFilters: new Set(),
      sortby: null,
      labelWidth: '',
      labelHeight: ''
    }
  },
  computed: {
    isQueryNotEmpty () {
      return Object.keys(this.$route.query).length
    },
    hasActiveFilters () {
      return this.$store.getters['category-next/hasActiveFilters']
    },
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getCategories: 'category/getCategories'
    }),
    getParentCategory () {
      return this.getCategories.filter(el => {
        return el.id === this.getCurrentCategory.parent_id
      })
    },
    getCurrentFilters () {
      return this.$store.getters['category-next/getCurrentFilters']
    },
    children () {
      if (!config.entities.category.categoriesDynamicPrefetch && (this.getCurrentCategory.children_data && this.getCurrentCategory.children_data.length > 0 && this.getCurrentCategory.children_data[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.getCurrentCategory.children_data
      } else {
        return this.getCategories.filter(c => { return c.parent_id === this.getCurrentCategory.id }) // return my child categories
      }
    },
    availableFilters () {
      return pickBy(this.filters, (filter, filterType) => { return (filter.length && !this.$store.getters['category-next/getSystemFilterNames'].includes(filterType)) })
    },
    activeFilters () {
      return this.getActiveCategoryFilters
    },
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    },
    diliveryFilter () {
      return this.$store.state.ui.diliveryFilter
    },
    widthFilter () {
      return this.$store.state.ui.widthFilter
    },
    heightFilter () {
      return this.$store.state.ui.heightFilter
    },
    isSortInQuery () {
      if (this.$route.query['sort']) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    $route (to, from) { // TODO: delete setTimeout
      if (to !== from) {
        setTimeout(() => { this.isShowLoader = false }, 200);
      }
    },
    value: {
      handler () {
        const defaultVariant = this.value && this.value.length ? this.value : products.defaultSortBy.attribute
        this.sortby = this.sortingVariants.find(variant => variant.id.includes(defaultVariant))
      },
      immediate: true
    }
  },
  mounted () {
    this.toggleFilters.clear()
  },
  methods: {
    resetAllFilters () {
      this.toggleFilters.clear()
      this.$store.dispatch('category-next/resetSearchFilters')
      this.$store.dispatch('ui/resetSaleFilter')
      this.$store.dispatch('ui/resetNewFilter')
      this.$store.dispatch('ui/resetHeightFilter')
      this.$store.dispatch('ui/resetWidthFilter')
      this.$store.dispatch('ui/resetDeliveryFilter')
    },
    changeOrder (variant) {
      this.sortby = variant
    },
    toggleDeliveryFilter (value) {
      let query = Object.assign({}, this.$route.query)
      if (this.diliveryFilter !== value) {
        this.$store.dispatch('ui/toggleDeliveryFilter', value).then(() => {
          if (this.diliveryFilter === '') {
            delete query.delivery
            this.$router.push({ query })
          } else {
            query.delivery = value
            this.$router.push({ query })
          }
        })
      } else {
        delete query.delivery
        this.$router.push({ query })
        this.$store.dispatch('ui/resetDeliveryFilter')
      }
    },
    slugToText (i) {
      let iArr = i.split('-')
      return iArr.join(' ')
    },
    selectedDimension  (arrId, type, label) {
      if (type === 'width') {
        let query = Object.assign({}, this.$route.query)
        if (this.widthFilter !== label) {
          this.$store.dispatch('ui/toggleWidthFilter', { arrId, label }).then(() => {
            if (this.widthFilter === '') {
              delete query.width
              this.$router.push({ query })
            } else {
              query.width = label
              this.$router.push({ query })
            }
          })
        } else {
          delete query.width
          this.$router.push({ query })
          this.$store.dispatch('ui/resetWidthFilter')
        }
        let words = label.split('-')
        this.labelWidth = 'SZEROKOŚĆ ' + words[0] + '-' + words[1] + (' (cm)')
        console.log(words)
      } else {
        let query = Object.assign({}, this.$route.query)
        if (this.heightFilter !== label) {
          this.$store.dispatch('ui/toggleHeightFilter', { arrId, label }).then(() => {
            if (this.heightFilter === '') {
              delete query.height
              this.$router.push({ query })
            } else {
              query.height = label
              this.$router.push({ query })
            }
          })
        } else {
          delete query.height
          this.$router.push({ query })
          this.$store.dispatch('ui/resetHeightFilter')
        }
        let words = label.split('-')
        this.labelHeight = 'WYSOKOŚĆ ' + words[0] + '-' + words[1] + (' (cm)')
      }
    },
    toggleSaleFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleSaleFilter').then(() => {
        if (!this.isSaleFilterActive) {
          delete query.sale
          this.$router.push({ query })
        } else {
          query.sale = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleNewFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleNewFilter').then(() => {
        if (!this.isNewFilterActive) {
          delete query.new
          this.$router.push({ query })
        } else {
          query.new = 'true'
          this.$router.push({ query })
        }
      })
    },
    sortById (filters) {
      return [...filters].sort((a, b) => {
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
    },
    toggleCurrent (filterIndex) {
      if (filterIndex) {
        let cache = this.toggleFilters
        if (!cache.has(filterIndex)) {
          cache.add(filterIndex)
        } else {
          cache.delete(filterIndex)
        }
        this.toggleFilters = null
        this.toggleFilters = cache
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.category-filter {
  &:hover {
    text-decoration: underline;
  }
}
.category-filters {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.safari-save {
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
    padding-bottom: 120px;
  }
}
.divider {
  height: 15px;
}
.filters {
  .cofnij {
    text-decoration: underline;
  }
  .loader-position {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .category-label {
    top: 5px;
  }
  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
    right: 20px;
    bottom: 22px;
  }
  .top {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .bottom {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
.price-box {
  max-width: 90%;
  margin: 0 auto;
}
.sidebar {
  &.fixed {
    // transition: all 0.25s;
    position: fixed;
    width: 204px;
    top: 119px !important;
    @media (max-width: 1200px) {
      width: 144px;
    }
    @media (max-width: 992px) {
      width: 106px;
    }
  }
  &.bottom-fixed {
    position: absolute;
    width: 204px;
    top: 1000px;
    @media (max-width: 1200px) {
      width: 144px;
    }
    @media (max-width: 992px) {
      width: 106px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__inline-selecors {
    display: flex;
  }
}
.nav-link {
  &::before {
    position: absolute;
    content: '';
    width: 14px;
    left: -30px;
    top: 1px;
    border: 1px solid #191919;
    height: 14px;
  }
}
.nav-link.active {
  &::after{
    position: absolute;
    content: '';
    background-color: #191919;
    width: 8px;
    left: -27px;
    top: 4px;
    border: 1px solid #191919;
    height: 8px;
  }
}
.close-icon {
  font-size: 8px;
}
</style>
