<template>
  <div id="category" class="bg-cl-white-smoke">
    <header>
      <div class="container p0">
        <div class="row m0">
          <div class="col-xs-12 col-md-2 tablet-breadcrumbs p0">
            <breadcrumbs class="category-breadcrumbs" />
          </div>
          <div class="col-xs-12 col-md-10 tablet-achievements border-box px10">
            <achievements-carousel :change-style="true" />
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div class="col-md-12 p0 hidden-xs align-left">
        <h1 class="h3 mt20 mb0 weight-700 uppercase title-font">
          {{ getH1_text }}
        </h1>
        <p v-if="isQueryFilterActive" @click="toggleQueryFilter()" class="align-center pointer p5 m0">
          {{ $t('Wyniki wyszukiwania dla') }}: {{ $route.query.query }}
        </p>
      </div>
    </div>
    <div class="container pb60 p0-mobile">
      <div v-if="getCurrentCategory.display_mode === 'PAGE' && !isQueryFilterActive">
        <div class="row mx8 mt15">
          <div
            :key="category.slug + 'page'"
            v-for="category in visibleCategories"
            class="col-xs-6 col-md-3 p0"
          >
            <div class="inner-kafelki bg-cl-white mr15">
              <router-link class="w-100" :to="categoryLink(category)">
                <div class="category-kafelki" v-if="category.thumbnail_image">
                  <img
                    :src="getThumbnailPath(category.thumbnail_image)"
                    sizes="(max-width: 768px) 480px, 800px"
                    :srcset="getThumbnailPath(category.thumbnail_image).replace('/1300/300/', '/414/92/') + ' 480w, ' + getThumbnailPath(category.thumbnail_image) + ' 800w'"
                    width="100%"
                    height="auto"
                    :alt="category.name"
                  >
                </div>
                <div class="category-kafelki" v-else>
                  <img src="/assets/placeholder.svg" width="100%" height="auto" alt="Category placeholder">
                </div>
                <p class="cl-alternative align-center m0 py20 fs-medium name-box">
                  {{ category.name }} ({{ category.product_count }})
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div v-show="getCurrentCategory.description" class="category-desc weight-400 lh25 mt30">
          <div id="styles-from-magento" v-html="getCurrentCategory.description" class="col-xs-12 px20 bg-cl-white py5" />
        </div>
      </div>
      <div v-else>
        <div v-if="getCurrentCategory.id !== 10" class="row m0 inner-container between-md">
          <sidebar class="hidden-xs-and-tablet" @changeFilter="changeFilter" :filters="getAvailableFilters" />
          <transition name="fade-in-up">
            <div class="col-xs-12 mobile-filters p0" v-if="mobileFilters">
              <div class="row col-xs-12 relative m0 center-xs brdr-bottom-1 brdr-cl-grey-smoke bg-cl-white">
                <div class="absolute btn-back visible-xs-and-tablet center-flex-text" @click="closeFilters">
                  <close-svg class="pointer" width="18px" height="100%" color="#191919" />
                </div>
                <p class="fs-medium my12">
                  Filtruj i sortuj
                </p>
              </div>
              <sidebar-mobile :filters="getAvailableFilters" :value="isSortInQuery" @changeFilter="changeFilter" />
              <div class="bottom-nav bg-cl-white save-bottom">
                <p class="close my8 uppercase" @click="closeFilters">
                  {{ $t('Pokaż produkty') }}<span class="pl5">({{ getCategoryProductsTotal }})</span>
                </p>
              </div>
            </div>
          </transition>
          <div class="col-md-10 pt20 px10 border-box products-list">
            <div class="row visible-xs relative pt15 pb10 px8 middle-xs">
              <div class="col-xs-6 h6">
                <span class="capitalize cl-dark-blue">{{ slugToText(getCurrentCategory.slug) }}</span>
                <span class="uppercase cl-matterhorn">({{ getCategoryProductsTotal }})</span>
              </div>
              <div class="col-xs-6 flex middle-xs between-xs relative">
                <offhand
                  context="category"
                  :code="'product_right_away'"
                  @change="changeFilter"
                />
                <i class="icon-size cl-alternative" :class="isRowMode ? 'icon-square-2' : 'icon-squares'" @click="isRowMode = !isRowMode" />
                <i class="icon-Filters icon-size cl-alternative" @click="openFilters" />
              </div>
            </div>
            <div v-if="getCurrentCategory.image" class="category-image-box bg-cl-white">
              <img :src="getThumbnailPath(getCurrentCategory.image)" class="category-image" :alt="getCurrentCategory.name">
            </div>
            <div v-else class="category-image-box bg-cl-white">
              <img src="/assets/category_banner.png" alt="category_banner" class="category-image">
            </div>
            <div class="mb10 actions-bar bg-cl-white py15 between-xs middle-xs">
              <offhand
                context="category"
                class="ml15"
                :code="'product_right_away'"
                @change="changeFilter"
              />
              <div class="flex middle-xs pr15">
                <p class="m0 productTotal-text pr10">
                  {{ start() }}
                  -
                  {{ end() }}
                  z
                  <span class="uppercase">{{ getCategoryProductsTotal }}</span>
                  produktów
                </p>
                <sort-by @change="changeFilter" class="" />
                <i class="icon-Filters filters-tablet px10 icon-size cl-alternative" @click="openFilters" />
                <div @click="isRowMode = false" class="px15 pointer">
                  <i class="icon-size icon-squares" :class="isRowMode ? 'cl-silver' : 'cl-alternative'" />
                </div>
                <div @click="isRowMode = true" class="pr10 pointer">
                  <i class="icon-size icon-square-2" :class="isRowMode ? 'cl-alternative' : 'cl-silver'" />
                </div>
                <ListingPagination
                  v-if="totalPages > 1"
                  class="product-list__pagination"
                  :current="currentPage"
                  :total="totalPages"
                  :visible="3"
                  :variant="2"
                />
              </div>
            </div>
            <active-filters class="visible-xs-and-tablet" :category-page="true" :filters="getCurrentFilters" :label-width="labelWidth" :label-height="labelHeight" @change="changeFilter" />
            <div v-if="isCategoryEmpty">
              <h4 data-testid="noProductsInfo">
                {{ $t('No products found!') }}
              </h4>
              <p>{{ $t('Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!') }}</p>
            </div>
            <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabled">
              <product-listing :category-id="getCurrentCategory.id" :is-row-mode="isRowMode" :columns="isRowMode ? 12 : 3" :mobile-columns="isRowMode ? 12 : 6" :products="products" />
            </lazy-hydrate>
            <product-listing v-else :category-id="getCurrentCategory.id" :is-row-mode="isRowMode" :columns="3" :mobile-columns="isRowMode ? 12 : 6" :products="products" />
            <div class="flex middle-xs between-sm center-xs px8">
              <p class="m0 productTotal-text pr10 hidden-xs">
                {{ start() }}
                -
                {{ end() }}
                z
                <span class="uppercase">{{ getCategoryProductsTotal }}</span>
                produktów
              </p>
              <ListingPagination
                v-if="totalPages > 1"
                class="product-list__pagination"
                :current="currentPage"
                :total="totalPages"
                :visible="3"
              />
            </div>
            <div v-if="loadingProducts" class="flex column middle-xs">
              <Loader />
              <p class="loader-text weight-600 cl-alternative mt0">
                Trwa ładowanie kolejnych produktów
              </p>
            </div>
            <div v-if="!routerPage || routerPage == 1" id="category-desc" class="mx8 category-desc weight-400 lh25 mt30">
              <div id="styles-from-magento" v-show="getCurrentCategory.description" v-html="getCurrentCategory.description" class="col-xs-12 px20 bg-cl-white py5" />
            </div>
          </div>
        </div>
        <div v-else class="row m0 pt15">
          <marki :categories="children" />
          <div class="col-sm-12 category-desc weight-400 cl-matterhorn lh25">
            <div id="styles-from-magento" v-html="getCurrentCategory.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Marki from '../components/core/blocks/Category/Marki.vue'
import Vue from 'vue'
import LazyHydrate from 'vue-lazy-hydration'
import Sidebar from '../components/core/blocks/Category/Sidebar.vue'
import SidebarMobile from '../components/core/blocks/Category/SidebarMobile.vue'
import {
  getThumbnailPath as _thumbnailHelper,
  buildFilterProductsQuery,
  isServer,
  getValueFor
} from '@vue-storefront/core/helpers'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import ProductListing from '../components/core/ProductListing.vue'
import Breadcrumbs from '../components/core/Breadcrumbs.vue'
import SortBy from '../components/core/SortBy.vue'
import AchievementsCarousel from 'theme/components/core/AchievementsCarousel.vue'
import Offhand from '../components/core/blocks/Category/Offhand.vue'
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers'
import config from 'config'
import { mapState, mapGetters } from 'vuex'
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks'
import { localizedRoute, currentStoreView } from '@vue-storefront/core/lib/multistore'
import { htmlDecode } from '@vue-storefront/core/filters'
import { MobileDetected } from '../components/core/mobileDetected.ts'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
import Loader from 'theme/components/core/LoadingSpinner.vue'
import ListingPagination from 'theme/components/core/ListingPagination.vue'
import ActiveFilters from 'theme/components/core/blocks/Category/ActiveFilters'
import sanitizeHtml from 'sanitize-html'
import uniq from 'lodash-es/uniq'

const THEME_PAGE_SIZE = 20

const composeInitialPageState = async (store, route, forceLoad = false) => {
  try {
    await store.dispatch('attribute/list', { // loading attributes for application use
      filterValues: uniq([...config.products.defaultFilters, ...config.entities.productListWithChildren.includeFields])
    })
    const filters = getSearchOptionsFromRouteParams(route.params)
    const cachedCategory = store.getters['category-next/getCategoryFrom'](route.path)
    const currentCategory = cachedCategory && !forceLoad ? cachedCategory : await store.dispatch('category-next/loadCategory', { filters: filters, includeFields: [...config.entities.category.includeFields, ...['description', 'meta-description']] })
    await store.dispatch('category-next/loadCategoryProducts', { route, category: currentCategory, pageSize: THEME_PAGE_SIZE })
    const breadCrumbsLoader = store.dispatch('category-next/loadCategoryBreadcrumbs', { category: currentCategory, currentRouteName: currentCategory.name, omitCurrent: true })

    if (isServer) await breadCrumbsLoader
    catalogHooksExecutors.categoryPageVisited(currentCategory)
  } catch (e) {
    console.error('Problem with setting Category initial data!', e)
  }
}

export default {
  components: {
    LazyHydrate,
    ProductListing,
    Breadcrumbs,
    Sidebar,
    SidebarMobile,
    SortBy,
    AchievementsCarousel,
    // SortByRadio,
    Offhand,
    Marki,
    CloseSvg,
    Loader,
    ListingPagination,
    ActiveFilters
  },
  mixins: [MobileDetected],
  data () {
    return {
      getMoreCategoryProducts: [],
      currentPage: 1,
      mobileFilters: false,
      loadingProducts: false,
      loading: true,
      isFixedActions: false,
      navVisible: true,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 150,
      isRowMode: false,
      labelWidth: '',
      labelHeight: ''
    }
  },
  computed: {
    ...mapGetters({
      getCurrentSearchQuery: 'category-next/getCurrentSearchQuery',
      getCategoryProducts: 'category-next/getCategoryProducts',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAvailableFilters: 'category-next/getAvailableFilters',
      getCategories: 'category/getCategories'
    }),
    totalPages () {
      return Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE);
    },
    routerPage () {
      return this.$route.query['page']
    },
    products () {
      return this.currentPage === 1
        ? this.getCategoryProducts
          .filter((product, i) => {
            return i < THEME_PAGE_SIZE;
          })
        : this.getMoreCategoryProducts
    },
    visibleCategories () {
      return this.getCategories.filter(category => {
        return category.parent_id === this.getCurrentCategory.id
      })
    },
    getCurrentFilters () {
      return this.$store.getters['category-next/getCurrentFilters']
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.includes('category-next.products')
    },
    getH1_text () {
      return this.getCurrentCategory.h1_text ? this.getCurrentCategory.h1_text : this.slugToText(this.getCurrentCategory.slug)
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0
    },
    children () {
      if (!config.entities.category.categoriesDynamicPrefetch && (this.getCurrentCategory.children_data && this.getCurrentCategory.children_data.length > 0 && this.getCurrentCategory.children_data[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.getCurrentCategory.children_data
      } else {
        return this.getCategories.filter(c => { return c.parent_id === this.getCurrentCategory.id }) // return my child categories
      }
    },
    isQueryFilterActive () {
      return this.$store.state.ui.isQueryFilterActive
    },
    isSortInQuery () {
      return this.$route.query['sort'] ? this.$route.query['sort'] : ''
    }
  },
  beforeMount () {
    this.$bus.$on('has-scrolled', this.hasScrolled)
  },
  mounted () {
    window.smoothscroll = () => {
      let toYcord = 130
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > toYcord) {
        window.requestAnimationFrame(window.smoothscroll)
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('has-scrolled', this.hasScrolled)
  },
  watch: {
    sortOrder () {
      if (this.currentPage > 1) {
        this.changePage();
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        if (from && from.query && from.query.page && !to.query.page) {
          this.initPagination()
        }
        if (to.query.page) {
          this.changePage(parseInt(to.query.page) || 1);
        }
      }
    }
  },
  async asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    if (context) context.output.cacheTags.add('category')
    await composeInitialPageState(store, route)
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) next() // SSR no need to invoke SW caching here
    else if (!from.name) { // SSR but client side invocation, we need to cache products and invoke requests from asyncData for offline support
      next(async vm => {
        vm.loading = true
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
        await composeInitialPageState(vm.$store, to, true)
        await vm.$store.dispatch('category-next/cacheProducts', { route: to }) // await here is because we must wait for the hydration
        vm.loading = false
      })
    } else { // Pure CSR, with no initial category state
      next(async vm => {
        vm.loading = true
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
        vm.$store.dispatch('category-next/cacheProducts', { route: to })
        vm.loading = false
      })
    }
  },
  methods: {
    sanitizeHtml: sanitizeHtml,
    start () {
      return (this.currentPage - 1) * THEME_PAGE_SIZE
    },
    end () {
      let end = (this.currentPage - 1) * THEME_PAGE_SIZE + THEME_PAGE_SIZE
      return this.getCategoryProductsTotal < end
        ? this.getCategoryProductsTotal
        : end
    },
    async changePage (page = this.currentPage) {
      const start = (page - 1) * THEME_PAGE_SIZE;
      if (
        start < 0 ||
        start >= this.getCategoryProductsTotal ||
        this.getCategoryProductsTotal < THEME_PAGE_SIZE
      ) {
        return;
      }
      const { includeFields, excludeFields } = config.entities.productList;
      const { filters } = this.getCurrentSearchQuery;
      const filterQr = buildFilterProductsQuery(
        this.getCurrentCategory,
        filters
      );
      // custom filters
      if (this.$store.state.ui.isSaleFilterActive) filterQr.applyFilter({ key: 'field', value: 'configurable_children.special_price', scope: 'custom' })
      const rangeqr = {}
      rangeqr['gte'] = 'now/d'
      if (this.$store.state.ui.isNewFilterActive) filterQr.applyFilter({ key: 'news_to_date', value: rangeqr })
      if (this.$store.state.ui.isQueryFilterActive) filterQr.setSearchText(this.$route.query['query'])
      if (this.$store.state.ui.diliveryFilter !== '') filterQr.applyFilter({ key: 'delivery_time', value: this.$store.state.ui.rangeForDilivery })
      if (this.$store.state.ui.widthFilter !== '') filterQr.applyFilter({ key: 'width_options', value: [this.$store.state.ui.rangeForWidth] })
      if (this.$store.state.ui.heightFilter !== '') filterQr.applyFilter({ key: 'height_options', value: [this.$store.state.ui.rangeForHeight] })
      if (this.$store.state.ui.isEcommerceFilterActive) {
        filterQr.applyFilter({ key: 'ecommerce', value: { 'eq': [getValueFor('ecommerce', 'yes'), getValueFor('ecommerce', 'both')] } })
        filterQr.applyFilter({ key: 'configurable_children.stock.qty', value: { 'gte': 1 } })
      } else {
        filterQr.applyFilter({ key: 'ecommerce', value: { 'eq': [getValueFor('ecommerce', 'no'), getValueFor('ecommerce', 'both')] } })
      }
      // end custom filters
      const searchResult = await quickSearchByQuery({
        query: filterQr,
        sort: this.sortOrder,
        start: start,
        size: THEME_PAGE_SIZE,
        includeFields: includeFields,
        excludeFields: excludeFields
      });
      this.getMoreCategoryProducts = await this.$store.dispatch(
        'category-next/processCategoryProducts',
        {
          products: searchResult.items,
          filters: filters
        }
      );
      this.currentPage = page;
      window.smoothscroll()
    },
    initPagination () {
      this.currentPage = 1;
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    getThumbnailPath (url) {
      if (url) {
        return _thumbnailHelper(url, 1300, 300, 'category')
      } else {
        return null
      }
    },
    toggleQueryFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleQueryFilter').then(() => {
        if (!this.isQueryFilterActive) {
          delete query.query
          this.$router.push({ query })
        }
      })
    },
    openFilters () {
      document.getElementsByTagName('body')[0].classList.add('no-scroll')
      this.mobileFilters = true
    },
    closeFilters () {
      document.getElementsByTagName('body')[0].classList.remove('no-scroll')
      this.mobileFilters = false
    },
    countOfFilters () {
      return Object.keys(this.$route.query).length
    },
    slugToText (i) {
      let iArr = i.split('-')
      return iArr.join(' ')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (this.scrollTop > this.lastScrollTop && this.scrollTop > this.navbarHeight) {
        this.navVisible = false
        this.isFixedActions = true
      } else if (this.scrollTop < this.navbarHeight) {
        this.isFixedActions = false
      } else {
        this.navVisible = true
      }
      this.lastScrollTop = this.scrollTop
    },
    async changeFilter (filterVariant) {
      this.$store.dispatch('category-next/switchSearchFilters', [filterVariant])
    }
  },
  metaInfo () {
    let title
    const meta = [
      {
        vmid: 'og:image',
        property: this.getCurrentCategory && this.getCurrentCategory.image ? 'og:image' : 'og:image',
        content: this.getCurrentCategory && this.getCurrentCategory.image ? 'https://api.artykulygrzewcze.pl/img/1300/300/resize/category/' + htmlDecode(this.getCurrentCategory.image) : 'https://artykulygrzewcze.pl/assets/category_banner.png'
      },
      {
        vmid: 'og:url',
        name: 'og:url',
        property: 'og:url',
        content: 'https://artykulygrzewcze.pl/' + this.getCurrentCategory.url_path
      },
      {
        vmid: 'og:type',
        name: 'og:type',
        property: 'og:type',
        content: 'object'
      },
      {
        rel: 'preload',
        as: 'image',
        href: this.getThumbnailPath(this.getCurrentCategory.image),
        imagesrcset: this.getThumbnailPath(this.getCurrentCategory.image) && (this.getThumbnailPath(this.getCurrentCategory.image).replace('/1300/300/', '/414/92/') + ' 480w, ' + this.getThumbnailPath(this.getCurrentCategory.image) + ' 800w'),
        imagesizes: '(max-width: 768px) 480px, 800px'
      },
      {
        rel: 'preload',
        as: 'image',
        href: (this.getCategoryProducts && this.getCategoryProducts[0] && this.getCategoryProducts[0].image) ? `https://api.artykulygrzewcze.pl/img/250/250/resize/product${this.getCategoryProducts[0].image}` : ''
      },
      {
        rel: 'preload',
        as: 'image',
        href: (this.getCategoryProducts && this.getCategoryProducts[1] && this.getCategoryProducts[1].image) ? `https://api.artykulygrzewcze.pl/img/250/250/resize/product${this.getCategoryProducts[1].image}` : ''
      },
      {
        rel: 'preload',
        as: 'image',
        href: (this.getCategoryProducts && this.getCategoryProducts[2] && this.getCategoryProducts[2].image) ? `https://api.artykulygrzewcze.pl/img/250/250/resize/product${this.getCategoryProducts[2].image}` : ''
      },
      {
        rel: 'preload',
        as: 'image',
        href: (this.getCategoryProducts && this.getCategoryProducts[3] && this.getCategoryProducts[3].image) ? `https://api.artykulygrzewcze.pl/img/250/250/resize/product${this.getCategoryProducts[3].image}` : ''
      },
      {
        rel: 'preload',
        as: 'image',
        href: (this.getCategoryProducts && this.getCategoryProducts[4] && this.getCategoryProducts[4].image) ? `https://api.artykulygrzewcze.pl/img/250/250/resize/product${this.getCategoryProducts[4].image}` : ''
      },
      {
        rel: 'preload',
        as: 'image',
        href: (this.getCategoryProducts && this.getCategoryProducts[5] && this.getCategoryProducts[5].image) ? `https://api.artykulygrzewcze.pl/img/250/250/resize/product${this.getCategoryProducts[5].image}` : ''
      }
    ];
    let queryParams = Object.keys(this.$route.query)
    queryParams = queryParams.filter(param => param !== 'page')
    if (queryParams.length) {
      meta.push({
        vmid: 'robots',
        name: 'robots',
        content: 'noindex'
      })
    }
    if (this.$route.query.new) {
      title = 'Nowości, nowe kolekcje grzejników na artykulygrzewcze.pl'
      meta.push({
        vmid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: 'Sprawdź nowe kolekcje Radox, IRSAP, P.M.H, Sunerzha i innych marek.'
      });
      meta.push({
        vmid: 'description',
        name: 'description',
        content: 'Sprawdź nowe kolekcje Radox, IRSAP, P.M.H, Sunerzha i innych marek.'
      });
      meta.push({
        vmid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: 'Nowości, nowe kolekcje grzejników na artykulygrzewcze.pl'
      });
      meta.push({
        vmid: 'og:site_name',
        name: 'og:site_name',
        property: 'og:site_name',
        content: 'Nowości, nowe kolekcje grzejników na artykulygrzewcze.pl'
      });
    } else if (this.$route.query.sale) {
      title = 'Wyprzedaż - Wyprzedaż grzejników, promocje'
      meta.push({
        vmid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: 'Wyprzedaż - Wyprzedaż grzejników, promocje'
      });
      meta.push({
        vmid: 'description',
        name: 'description',
        content: 'Wyprzedaż - Wyprzedaż grzejników, promocje'
      });
      meta.push({
        vmid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: 'Wyprzedaż - Wyprzedaż grzejników, promocje | GrzejnikiDesign'
      });
      meta.push({
        vmid: 'og:site_name',
        name: 'og:site_name',
        property: 'og:site_name',
        content: 'Wyprzedaż - Wyprzedaż grzejników, promocje | GrzejnikiDesign'
      });
    } else if (this.getCurrentCategory.meta_description || this.getCurrentCategory.description || this.getCurrentCategory.name) {
      let description = this.getCurrentCategory.meta_description || this.getCurrentCategory.description
      description = description && this.sanitizeHtml(description, { allowedTags: [], allowedAttributes: {} })
      let lengthDescription = description && description.slice(0, 160)
      if (description) {
        let lastIndex = lengthDescription.lastIndexOf(' ')
        lengthDescription = lengthDescription.slice(0, lastIndex)
      }
      title = htmlDecode(this.getCurrentCategory.meta_title) || this.getCurrentCategory.name
      meta.push({
        vmid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: lengthDescription
      });
      meta.push({
        vmid: 'description',
        name: 'description',
        content: lengthDescription
      });
      meta.push({
        vmid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: (this.getCurrentCategory.meta_title || this.getCurrentCategory.name) +
          ' - Sprawdź na artykulygrzewcze.pl'
      });
      meta.push({
        vmid: 'og:site_name',
        name: 'og:site_name',
        property: 'og:site_name',
        content: (htmlDecode(this.getCurrentCategory.meta_title) || this.getCurrentCategory.name) +
          ' - Sprawdź na artykulygrzewcze.pl'
      });
    }
    let canonicalLink
    if (this.$route.query.new && this.$route.path === '/kategorie-produktow') {
      canonicalLink = {
        rel: 'canonical',
        href: config.server.hostName + localizedRoute('/' + this.getCurrentCategory.url_path) + '?new=true'
      }
    } else if (this.$route.query.sale && this.$route.path === '/kategorie-produktow') {
      canonicalLink = {
        rel: 'canonical',
        href: config.server.hostName + localizedRoute('/' + this.getCurrentCategory.url_path) + '?sale=true'
      }
    } else {
      canonicalLink = {
        rel: 'canonical',
        href: config.server.hostName + localizedRoute('/' + this.getCurrentCategory.url_path)
      }
    }
    let prevLink = {}
    let nextLink = {}
    let pageTotal = Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE)
    if (this.$route.query.page) {
      if (parseInt(this.$route.query.page) > 1) {
        prevLink = {
          rel: 'prev',
          href: localizedRoute('/' + this.getCurrentCategory.url_path) + '?page=' + (parseInt(this.$route.query.page) - 1)
        }
      }
      if (pageTotal > this.$route.query.page) {
        nextLink = {
          rel: 'next',
          href: localizedRoute('/' + this.getCurrentCategory.url_path) + '?page=' + (parseInt(this.$route.query.page) + 1)
        }
      }
    }
    return {
      link: [
        prevLink && prevLink,
        nextLink && nextLink,
        canonicalLink
      ],
      title,
      meta
    };
  }
}
</script>

<style scoped>
.category-desc {
  color: #343434;
}
.category-desc >>> p {
  font-size: 14px;
  line-height: 20px;
}
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$border-color: color(matterhorn);

.filters-tablet {
  display: none;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: flex !important;
  }
}
.tablet-achievements {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.tablet-breadcrumbs {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis:0;
    max-width: 0;
  }
}
.loader-text {
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.icon-size {
  font-size: 28px;
}
.productTotal-text {
  font-size: 14px;
  @media (max-width: 992px) {
    font-size: 12px;
  }
}
.inner-kafelki {
  box-sizing: border-box;
  margin-bottom: 15px;
  &:hover {
    margin-bottom: 11px;
    border: 2px solid #e0e0e0;
  }
}
.category-kafelki {
  max-height: 400px;
  min-height: 250px;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  padding: 5px 10px;
}
.name-box {
  border-top: 8px solid #3859A6;
}
.category-image-box {
  max-height: 300px;
  .category-image {
    width: 100%;
    height: auto;
    max-height: 250px;
  }
}
.btn-back {
  left: 17px;
  top: 14%;
  width: 30px;
  height: 30px;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
}
.inner-container {
  padding-top: 5px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.p0-mobile {
  padding: 0;
}
.category-breadcrumbs {
  @media (max-width: 768px) {
    background: #f5f5f5;
  }
}
.action-icon {
  margin-left: 10px;
  border: solid #041D59;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  margin-bottom: 3px;
}
#category {
  min-height: 60vh;
}
.image-box {
  max-height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-filters {
  width: 15.66666667%;
  max-width: 15.66666667%;
}
.actions-bar {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .h50 {
    height: 50px;
  }
  .header-row {
    top: 0;
    transition: 0.3s all ease;
  }
  .fixed-actions {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    top: 40px;
    background-color: white;
  }
}
.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .category-filters {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: white;
  }
}
.mobile-filters {
  display: none;
  overflow: auto;
}

.mobile-filters-button {
  display: none;
}

.category-title {
  line-height: 65px;
}
.product-list__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.products-list {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .category-title {
    margin: 0;
    font-size: 36px;
    line-height: 40px;
  }

  .products-list {
    padding: 0;
    width: 100%;
    max-width: none;
  }

  .product-listing {
    justify-content: center;
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    .mobile-filters-button {
    position: relative;
    display: block;
    height: 35px;
    border: 2px solid;
    border-right: 0px;
    border-left: 0px;
    .active-index {
      position: absolute;
      background-color: #da3800;
      color: white;
      width: 31px;
      top: 0px;
      right: 0;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile-filters {
    display: block;
    position: fixed;
    background-color: #ffffff;
    z-index: 5;
    // padding: 0 20px;
    left: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    box-sizing: border-box;
  }
}

.bottom-nav {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .close {
      width: 90%;
      border: 1px solid #FF8100;
      background-color: #FF8100;
      font-size: 15px;
      padding: 8px 0px;
      text-align: center;
      color: white;
      border-radius: 30px;
    }
}

.mb1000 {
  margin-bottom: 1000px;
}
.mt20-xs {
  @media (max-width: 767px) {
    margin-top: 20px;
  }
}
/deep/ .category-desc a {
  color: blue !important;
  text-decoration: underline !important;
}
</style>
