<template>
  <div :class="categoryPage ? 'category-active-filters' : 'active-filters'">
    <div
      v-for="(filter, filterIndex) in filters"
      :key="filterIndex"
      v-if="filterIndex !== 'product_right_away' && filterIndex !== 'height' && filterIndex !== 'width'"
      class="flex wrap"
    >
      <template v-if="isFiltersAreArrays()">
        <p
          v-for="(option, index) in filter"
          :key="index"
          class="pointer m0 capitalize p5 bg-cl-black cl-white mr10 mb5 flex middle-xs"
          :class="{'active-filter-label': categoryPage}"
        >
          <template v-if="filterIndex === 'bestseller' || filterIndex === 'hit_of_the_month' || filterIndex === 'hit_of_the_week' || filterIndex === 'outlet'">
            {{ $t(option.type) }}
          </template>
          <template v-else>
            {{ beautify(option.label) }}
          </template>
          <span @click="$emit('change', option)" class="pl5 lh0">
            <close-svg width="18px" height="100%" color="#ffffff" />
          </span>
        </p>
      </template>
      <template v-else>
        <p
          class="pointer m0 p5 bg-cl-black cl-white mr10 mb5 flex middle-xs"
        >
          {{ $t(filter.label) }}
          <span @click="$emit('change', filter)" class="pl5 lh0">
            <close-svg width="18px" height="100%" color="#ffffff" />
          </span>
        </p>
      </template>
    </div>
    <div class="flex wrap">
      <p v-if="widthFilter" :class="{'active-filter-label': categoryPage}" class="pointer m0 p5 bg-cl-black cl-white mr10 mb5 flex middle-xs">
        {{ labelWidth }}
        <span @click="clearWidthFilter()" class="pl5 lh0">
          <close-svg width="18px" height="100%" color="#ffffff" />
        </span>
      </p>
      <p v-if="heightFilter" :class="{'active-filter-label': categoryPage}" class="pointer m0 p5 bg-cl-black cl-white mr10 mb5 flex middle-xs">
        {{ labelHeight }}
        <span @click="clearHeightFilter()" class="pl5 lh0">
          <close-svg width="18px" height="100%" color="#ffffff" />
        </span>
      </p>
      <p v-if="isSaleFilterActive" :class="{'active-filter-label': categoryPage}" class="pointer m0 p5 bg-cl-black cl-white mr10 mb5 flex middle-xs">
        {{ $t('Sale') }}
        <span @click="toggleSaleFilter()" class="pl5 lh0">
          <close-svg width="18px" height="100%" color="#ffffff" />
        </span>
      </p>
      <p v-if="isNewFilterActive" :class="{'active-filter-label': categoryPage}" class="pointer m0 p5 bg-cl-black cl-white mr10 mb5 flex middle-xs">
        {{ $t('Nowości') }}
        <span @click="toggleNewFilter()" :class="{'active-filter-label': categoryPage}" class="pl5 lh0">
          <close-svg width="18px" height="100%" color="#ffffff" />
        </span>
      </p>
      <p v-if="diliveryFilter" :class="{'active-filter-label': categoryPage}" class="pointer m0 p5 bg-cl-black cl-white mr10 mb5 flex middle-xs">
        Wysyłka {{ diliveryFilter }}
        <span @click="toggleDeliveryFilter()" class="pl5 lh0">
          <close-svg width="18px" height="100%" color="#ffffff" />
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash-es/pickBy'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'

export default {
  name: 'ActiveFilters',
  components: {
    CloseSvg
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    labelWidth: {
      type: String,
      required: false,
      default: ''
    },
    labelHeight: {
      type: String,
      required: false,
      default: ''
    },
    categoryPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    },
    diliveryFilter () {
      return this.$store.state.ui.diliveryFilter
    },
    widthFilter () {
      return this.$store.state.ui.widthFilter
    },
    heightFilter () {
      return this.$store.state.ui.heightFilter
    }
  },
  methods: {
    beautify (label) {
      if (label === 'W') {
        return 'Wodny'
      } else if (label === 'WM') {
        return 'Wodny z opcją elektryczną'
      } else if (label === 'E') {
        return 'Elektryczny'
      } else if (label === 'M') {
        return 'Wodno-elektryczny'
      } else if (label === 'Yes') {
        return 'Tak'
      } else if (label === 'No') {
        return 'Nie'
      } else {
        return label
      }
    },
    clearHeightFilter () {
      let query = Object.assign({}, this.$route.query)
      delete query.height
      this.$router.push({ query })
      this.$store.dispatch('ui/resetHeightFilter')
    },
    clearWidthFilter () {
      let query = Object.assign({}, this.$route.query)
      delete query.width
      this.$router.push({ query })
      this.$store.dispatch('ui/resetWidthFilter')
    },
    // clearWidthAndHeightFilters (type) {
    //   if (this.filters.width && type === 'width') {
    //     this.$store.dispatch('category-next/switchSearchFilters', this.filters.width)
    //   } else if (this.filters.height && type === 'height') {
    //     this.$store.dispatch('category-next/switchSearchFilters', this.filters.height)
    //   }
    // },
    toggleSaleFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleSaleFilter').then(() => {
        if (!this.isSaleFilterActive) {
          delete query.sale
          this.$router.push({ query })
        } else {
          query.sale = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleNewFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleNewFilter').then(() => {
        if (!this.isNewFilterActive) {
          delete query.new
          this.$router.push({ query })
        } else {
          query.new = 'true'
          this.$router.push({ query })
        }
      })
    },
    isFiltersAreArrays () {
      let filterValues = Object.values(this.filters)
      if (Array.isArray(filterValues[0])) {
        return true
      } else {
        return false
      }
    },
    toggleDeliveryFilter (value) {
      let query = Object.assign({}, this.$route.query)
      if (this.diliveryFilter !== value) {
        this.$store.dispatch('ui/toggleDeliveryFilter', value).then(() => {
          if (this.diliveryFilter === '') {
            delete query.delivery
            this.$router.push({ query })
          } else {
            query.delivery = value
            this.$router.push({ query })
          }
        })
      } else {
        delete query.delivery
        this.$router.push({ query })
        this.$store.dispatch('ui/resetDeliveryFilter')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

.active-filter-label {
  border-radius: 16px;
  background: #041D59 !important;
}

.active-filters {
  width: 100%;
  margin: 0;
  padding: 0;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    margin: 0 0 10px 0;
    padding: 0px 20px;
    width: auto;
  }
}

.category-active-filters {
  width: 100%;
  margin: 0;
  padding: 0;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    margin: 0 0 10px 0;
    width: auto;
  }
  @media (max-width: 767px) {
    margin: 0 0 10px 0;
    width: auto;
    padding: 0 8px;
  }
}

.inline-block {
  display: inline-block;
}

.lh0 {
  line-height: 0;
}
</style>
