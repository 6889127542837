<template>
  <button
    class="
      p0 bg-cl-primary relative
      brdr-square h5 cl-black weight-400 size-selector
    "
    :style="colorFrom"
    :class="{ active: isActive }"
    @click="$emit('change', variant)"
    :aria-label="variant.label"
  >
    <span class="hover-label absolute capitalize">
      {{ $t(colorLabel) }}
    </span>
  </button>
</template>

<script>
import config from 'config'
import filterMixin from 'theme/mixins/filterMixin.ts'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'

export default {
  mixins: [filterMixin, getLabelMixin],
  computed: {
    colorLabel () {
      return this.getLabelforValue('color_aggregated', this.variant.label)
    },
    colorFrom () {
      let label = this.colorLabel
      if (label === 'chrome') {
        return 'background: rgb(182,170,170); background: linear-gradient(90deg, rgba(182,170,170,1) 8%, rgba(255,255,255,1) 41%, rgba(184,175,175,1) 92%);'
      } else if (label === 'inox') {
        return 'background: rgb(182,170,170); background: linear-gradient(90deg, rgba(182,170,170,1) 8%, rgba(255,255,255,1) 41%, rgba(184,175,175,1) 92%);'
      } if (label === 'mix_color') {
        return 'background: linear-gradient(to right, orange , yellow, green, cyan, blue, violet);'
      }
      if (config.products.colorMappings) {
        if (typeof config.products.colorMappings[label] !== 'undefined') {
          label = config.products.colorMappings[label]
        }
      }
      return 'background-color: ' + label
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: color(primary-orange);
  $color-disabled: color(secondary, $colors-border);
  $color-text: color(black);

  .size-selector {
    width: fit-content;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 0;
    margin-bottom: 10px;
    margin-left: 10px;
    box-shadow: 1px 1px 4px 0px;
    // &:hover,
    &:focus {
      box-shadow: 0px 0px 0px 2px #ff8100;
    }

    &.active {
      box-shadow: 0px 0px 0px 2px #ff8100;
    }

    &:disabled {
      border-color: $color-disabled;
      color: $color-disabled;
      cursor: not-allowed;
    }
    &.disabled {
      color: $color-disabled;
      cursor: not-allowed;
      box-shadow: none;
      opacity: 0.2;
      .hover-label {
        display: none !important;
      }
    }
    &:hover {
      .hover-label {
      display: flex;
      }
    }
    .hover-label {
      min-width: 80px;
      left: -50%;
      bottom: 45px;
      font-size: 12px;
      background-color: white;
      padding: 5px;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      .color {
        margin-bottom: 5px;
        width: 65px;
        height: 65px;
      }
    }
  }
  .mr-auto {
    margin-right: auto;
  }
</style>
