<template>
  <div class="switch-container relative">
    <label class="h6 cl-forest-green visible-xs absolute label-position">
      Od ręki
    </label>
    <div class="flex middle-xs custom-switch">
      <label class="label-text cl-forest-green hidden-xs">
        Od ręki
      </label>
      <label for="switch" class="switch">
        <label for="switch" class="hidden">Od ręki</label>
        <input id="switch" name="switch" type="checkbox">
        <span class="slider round" :class="{ 'active': isEcommreceFilterActive }" @click="toggleEcommreceFilter()" />
      </label>
    </div>
  </div>
</template>

<script>
import config from 'config'
import pickBy from 'lodash-es/pickBy'
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  mixins: [filterMixin],
  methods: {
    toggleEcommreceFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleEcommerceFilter').then(() => {
        if (!this.isEcommreceFilterActive) {
          delete query.rightAway
          this.$router.push({ query })
        } else {
          query.rightAway = 'true'
          this.$router.push({ query })
        }
      })
    }
  },
  computed: {
    isEcommreceFilterActive () {
      return this.$store.state.ui.isEcommerceFilterActive
    }
  }
}
</script>

<style lang="scss" scoped>
.label-text {
  padding: 5px 15px 5px 15px;
  @media (max-width: 992px) {
    font-size: 12px;
    padding: 5px;
  }
}
.switch-container {
  @media (max-width: 767px) {
    width: 55px;
    text-align: center;
  }
}
.label-position {
  top: -15px;
  left: 7px;
  width: fit-content;
}
.custom-switch {
  border: 1px solid #7DBA10;
  border-radius: 19px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;
  @media (max-width: 767px) {
    height: 22px;
  }
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 33px;
  width: 33px;
  left: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  @media (max-width: 767px) {
    height: 21px;
    width: 21px;
  }
}
.active.slider {
  background-color: #7DBA10;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.active.slider:before {
  transform: translateX(31px);
}
.slider.round {
  border-radius: 19px;
}
.slider.round:before {
  border-radius: 50%;
  border: 1px solid #7DBA10;
}
</style>
