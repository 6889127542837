<template>
  <div class="sort-by flex middle-xs px10">
    <label for="sortby" class="label">Sortuj wg</label>
    <select
      id="sortby"
      name="sortby"
      class="cl-black weight-500"
      v-model="sortby"
      @change="changeOrder"
    >
      <option selected="selected" disabled value="" v-if="!hasLabel">
        {{ $t('Sort By') }}
      </option>
      <option v-for="variant in sortingVariants" :value="variant" :key="variant.id">
        {{ $t(variant.label) }}
      </option>
    </select>
  </div>
</template>

<script>
import SortBy from '@vue-storefront/core/compatibility/components/SortBy'
import { products } from 'config'
export default {
  mixins: [SortBy],
  props: {
    hasLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sortby: null
    }
  },
  watch: {
    value: {
      handler () {
        const defaultVariant = this.value && this.value.length ? this.value : products.defaultSortBy.attribute
        this.sortby = this.sortingVariants.find(variant => variant.id.includes(defaultVariant))
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
    @import '~theme/css/base/text';
    @import '~theme/css/variables/colors';
    @import '~theme/css/helpers/functions/color';
    $color-tertiary: color(tertiary);
    .sort-by {
        position: relative;
        border: 1px solid #D9D9D9;
        border-radius: 19px;
        height: 34px;
        .label {
          width: 70px;
          font-size: 14px;
          @media (max-width: 992px) {
            font-size: 12px;
          }
        }
        select {
            @extend .h4;
              font-size: 12px;
              background-color: transparent;
              color: #000000;
              border: 0;
              font-weight: 500;
              margin-right: 0;
              text-align: center;
            &:focus {
                outline: none;
            }
            option {
              &:hover {
                // background-color: white;
              }
              font-size: 14px;
              background: rgb(255, 255, 255);
              color: #000;
              padding: 5px;
              margin: 5px;
            }
        }
        &__icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
    @media (max-width: 770px) {
      .sort-by {
        width: 50%;
      }
    }
  .display-none {
    display: none;
  }
</style>
