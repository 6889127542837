<template>
  <div class="col-sm-12 col-xs-12">
    <div class="pr-letters brdr-top-1 brdr-bottom-1 brdr-cl-black py5">
      <a v-for="(letter, index) in alphabetArray"
         v-if="letterCategory(letter).length > 0"
         :key="index"
         @click="scrollMeTo(letter)"
         class="letters pointer no-underline h4 mx5 uppercase"
      >
        {{ letter }}
      </a>
    </div>
    <h2>{{ $t('Producenci w Aggrzejnikach od A do Z') }}</h2>
    <p>{{ $t('Prezentujemy najpopularniejsze marki dostępne w Aggrzejnikach.pl') }}</p>
    <div
      v-for="(letter, index) in alphabetArray"
      :key="index"
      class="mt35"
      v-if="letterCategory(letter).length > 0"
    >
      <div class="letter-box mt15 mb25" :ref="letter">
        <p class="letter-p uppercase brdr-1 brdr-cl-black h3">
          {{ letter }}
        </p>
      </div>
      <div class="row">
        <div v-for="category in letterCategory(letter)" :key="category.id" class="col-xs-6 col-sm-4 mb10">
          <router-link
            class="no-underline mx10"
            :to="localizedRoute('/' + category.url_path)"
          >
            {{ category.name }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- <div
        :key="category.slug"
        v-for="category in children"
        class="align-center h4 uppercase"
    >
        <router-link
        class="no-underline mx10"
        :to="localizedRoute({ name: 'category', fullPath: category.url_path, params: { id: category.id, slug: category.slug }})"
        >{{ category.name }}</router-link>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      alphabet: 'abcdefghijklmnopqrstuvwxyz'
    }
  },
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  methods: {
    scrollMeTo (refName) {
      let element = this.$refs[refName]
      let top = element[0].offsetTop
      let topWithoutOffset = top - 100

      window.scrollTo(0, topWithoutOffset)
    },
    letterCategory (letter) {
      return this.categories.filter(c => { return c.name.charAt(0).toLowerCase() === letter })
    }
  },
  computed: {
    alphabetArray () {
      var alphabetArray = []
      alphabetArray = this.alphabet.split('')
      return alphabetArray
    }
  }
}
</script>

<style lang="scss" scoped>
.pr-letters {
  display: flex;
  flex-wrap: wrap;
}
.letter-box {
  .letter-p {
    display: inline;
    padding: 5px;
    margin: 0;
  }
  &:before {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 5%;
    border-bottom: 1px solid;
  }
  &:after {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 90%;
    border-bottom: 1px solid;
    @media (max-width: 767px) {
      width: 80%;
    }
  }
}
</style>
